import React from "react"
import "firebase/auth"
import "firebase/database"
// import "./node_modules/bootstrap/dist/css/bootstrap.min.css"
import "bootswatch/dist/simplex/bootstrap.min.css"
import './src/css/global.css'

import { shouldUpdateScrollFn } from "acciondigital-basic-theme/src/utils/gatsbyBrowserUtils"
import { wrapPageElementQueryProviderBase as wrapPageElementQueryProvider } from "acciondigital-basic-theme/src/utils/wrapPageElementQueryProviderBase"

// import NavMenuProvider from "./src/context/NavMenuContext"
import RootElement from "./src/components/rootElement"

// http://bootstrap.themes.guide/herbie/
// https://bootstrap.build/app/project/iIFADbwMUkHo
// https://startbootstrap.com/

//alexluong/gatsby-packages
//gatsby-plugin-use-query-params jumps to top of the page when a query param is set #33
//https://github.com/alexluong/gatsby-packages/issues/33
export const shouldUpdateScroll = shouldUpdateScrollFn

export const wrapPageElement = wrapPageElementQueryProvider

// const { netlifySiteId: sitio } = infoSitio
// console.log("===========>>>> gatsby-browser",process.env.GATSBY_NETLIFY_SITE_ID)

// <NavMenuProvider>{element}</NavMenuProvider>
// export const wrapRootElement = ({ element }) => (
//   <AuthProvider sitio={sitio}>
//     <CardProvider sitio={sitio} saveToFirebase={true} vigenciaDiasDefault={-1}>
//       {element}
//     </CardProvider>
//   </AuthProvider>
// )

export const wrapRootElement = ({ element }) => (
  <RootElement>{element}</RootElement>
)
